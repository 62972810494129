.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    max-width: 297px;
    border-radius: 20px;

    cursor: pointer;
  }

  .errorsBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .errorMessage {
      font-size: 12px;
      color: #d00074;
    }
  }
}
