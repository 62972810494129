.main {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 11px;
	cursor: pointer;
	border: none;
	outline: none;
	padding: 0;
	background-color: transparent;

	.icon {
		display: block;
		// width: 100%;
		// height: 100%;
		object-fit: contain;
	}
}
