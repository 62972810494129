@import '~assets/styles/variables';

.menuItem {
  padding: 8px 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $primary-light02;
  }
}

.actionsList {
    padding: unset !important;
}

.paper {
  box-shadow: 0px 0px 20px 0px rgba(33, 75, 165, 0.15);
  border-radius: 8px;
  border: 1px solid $primary-light;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 140px;

  .actionsText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #1c2f4c;
  }
}
