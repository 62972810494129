@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 600px;
}

.challenges {
  border-collapse: collapse;

  &__wrapper {
    width: 100%;
    overflow: auto;
  }

  th {
    font-size: 12px;
    font-style: normal;
    padding: 10px 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.208px;
    color: $primary-main;
    background-color: $primary-bg;

    &:first-child {
      border-radius: 8px 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }

  td {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.208px;
    padding: 10px 16px;
  }

  &_photo {
    min-width: 80px;
  }
  &_title, &_subtitle {
    min-width: 222px;
  }
  &_description {
    min-width: 298px;
  }
  &_startDate, &_deadline {
    min-width: 254px;

    &_wrapper {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      width: max-content;

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.3px;
      }
    }
  }

  &_startDate {
    &_wrapper {
      background-color: $primary-light02;
      color: $primary-main;
    }
  }

  &_deadline {
    &_wrapper {
      background-color: #FFF0DE;
      color: #E47C00;

      svg path {
        fill: #E47C00;
      }
    }
  }

  &_row_title, &_row_subtitle, &_row_description {
    span {
      @include multilineThreeDots(2)
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
}
