.title {
	margin-top: 2.6vh;
	margin-bottom: 3vh;
	font-size: 18px;
}

.actions {
	margin-top: 2.7vh;
	max-width: 277px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	button:first-child {
		margin-bottom: 15px;
	}
}
