.chesedIdea {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  &__row {
    display: flex;
    align-items: center;
  }
}

.removeIdea {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  svg {
    display: block;
  }

  &_hide {
    svg {
      display: none;
    }
  }
}
