.marked_day {
	background-color: #c6ccfe;
	border-radius: 50%;
}
.button_arrow {
	width: 7px;
	height: 12px;

	svg {
		width: inherit;
		height: inherit;
	}
}

.datepicker {
  position: relative;
}
