.image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  min-height: 79px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
}
