.title,
h1,
h2 {
	font-weight: 500;
}

.title,
.link {
	letter-spacing: -0.208px;
}

.title {
	font-size: 24px;
	line-height: 26px;
}

h1 {
	font-size: 80px;
	letter-spacing: -5px;
}

h2 {
	font-size: 48px;
	letter-spacing: -3.5px;
}

.link {
	font-size: 18px;
	line-height: 18px;
}
