@import '~assets/styles/mixins';

.container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

  &.volunteer {
    height: fit-content;
  }

	&_live_screen {
		padding: 5vh 48px 3.5vh 48px;
		div:first-child {
			max-width: unset;
		}
	}

	&.absolute_disable {
		position: static;
		height: auto;
	}

	.appeal {
		max-width: 495px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 6.3vh;

		span:first-child {
			margin-bottom: 3vh;
		}

		.subtitle {
			display: block;
			font-size: 18px;
			line-height: 23px;
			letter-spacing: -0.01em;
			text-align: center;
			margin-bottom: 4vh;
		}
	}

	.options {
		max-width: 260px;
		width: 100%;
		margin: 0 auto;
	}

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}
}
