@import '~assets/styles/variables';

.content__container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	padding: 3.8vh 2.7vw;
	background-color: $container-bg;
}

.content__wrapper {
	max-width: 1225px;
	width: 100%;
	display: flex;
	margin: 0 auto;
	margin-top: 4vh;
	position: relative;
}
