.chatInfo {
	position: relative;
}
.chatInfo__image {
	width: 54px;
	height: 54px;
	border-radius: 50%;
}
.chatInfo__counter {
	width: 23px;
	height: 23px;
	background: #ffa133;
	border: 1px solid #ffffff;
	border-radius: 50%;
	font-weight: 500;
	font-size: 11px;
	line-height: 22px;
	color: #ffffff;
	position: absolute;
	top: -3px;
	right: -3px;
	text-align: center;
	overflow: hidden;
}
