.contact {
	// max-width: 95px;
	// min-width: 75px;
	align-self: center;
	justify-self: center;

	.popup_title {
		margin-bottom: 30px;
		font-size: 24px;
		line-height: 26px;
		letter-spacing: -0.208px;
		font-weight: 500;
	}
}

.button {
	width: 82px !important;
}
