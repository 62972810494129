// Global styles file

//base
@import '~assets/styles/normalize';
@import '~assets/styles/base';
@import '~assets/styles/typography';

//common
@import '~assets/styles/variables';

@import '~assets/styles/mixins';

h1,
h2 {
	margin: 0;
}

* {
	box-sizing: border-box;
}

button {
	outline: none;
	cursor: pointer;
	border: none;
}

/* Works on Firefox */
// * {
// 	scrollbar-color: $primary-main $primary-bg;
// }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar-thumb {
	width: 5px;
	height: 5px;
	border-radius: 4px;
	background-color: rgba(33, 75, 165, 0.5);
	&:hover {
		background-color: rgba(33, 75, 165, 1);
	}
}
*::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

*::-webkit-scrollbar-track {
	background: $primary-bg;
}
