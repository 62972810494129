@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input__text {
	position: relative;
	width: 100%;

	input {
		@include inputMain;

		&:not(:focus) {
			& + .input__text_edit {
				opacity: 1;
			}
		}
	}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

	&_edit {
		@include inputEdit;
	}
}
