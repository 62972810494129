@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	width: 100%;
	width: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.2s ease;

	.picker_input {
		width: 100%;
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		/* Firefox */
		&[type='number'] {
			-moz-appearance: textfield;
		}

		@include inputMain;
		padding: 5px;
		text-align: center;
		transition: all 0.2s ease;
	}

	.arrow_less,
	.arrow_more {
		width: 15px;
		height: 15px;
		cursor: pointer;
		object-fit: contain;
		padding: 10px 5px;
		box-sizing: content-box;
		border-radius: 10px;
		transition: all 0.2s ease;

		&:hover {
			background-color: $primary-bg;
		}
	}

	.arrow_less {
		transform: rotateZ(90deg);
	}

	.arrow_more {
		transform: rotateZ(-90deg);
	}

	.field_name {
		text-transform: capitalize;
		color: $text-light;
	}
}
