@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.share_dd {
	background: #ffffff;
	border: 1px solid #f1f3ff;
	box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
	border-radius: 8px;
	width: 239px;
	min-height: 323px;
	padding: 24px 32px;
	position: absolute;
	top: -10px;
	right: 0;
	z-index: 2;
	@include changeOpacityToVisibleAnimation;
}
.share_viaItem {
	width: 180px;
	height: 45px;
	border: 1px solid #c6ccfe;
	border-radius: 30px;
	overflow: hidden;
	margin-bottom: 14px;
	display: flex;
	padding: 0 25px;
	align-items: center;
	background-color: #fff;

	&:hover {
		background-color: $primary-bg;
		cursor: pointer;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.disabled {
		pointer-events: none;
		background-color: $disabled;
	}
}
.share_ttl {
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: -0.3px;
	color: $text-main;
	margin-bottom: 28px;
}
.share_close {
	position: absolute;
	color: #214ba5;
	top: 25px;
	right: 22px;
	font-size: 40px;
	transform: rotate(45deg);
	cursor: pointer;
}
.viaItem__ico {
	width: 19px;
	height: 20px;
	object-fit: contain;
	margin-right: 16px;
}
