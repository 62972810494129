.main {
	width: 21px;
	height: 21px;
	border: none;
	outline: none;
	background-color: transparent;
	padding: 0;
	border-radius: 50%;
	cursor: pointer;

	&:first-child {
		margin-right: 7px;
	}

	&:nth-child(2) {
		margin-right: 7px;
	}

	.success,
	.reject {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: block;
	}

	.success_disabled {
		filter: grayscale(1);
	}

	.reject_disabled {
		filter: invert(153%) sepia(70%) saturate(111%) hue-rotate(177deg) brightness(128%) contrast(61%);
	}
}
