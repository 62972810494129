@import '~assets/styles/variables';
@import '~assets/styles/mixins';

button.outlined {
	@include font-button;
	text-wrap: nowrap;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 72px;
	height: 21px;
	padding: 4px 6px;
	border: 1px solid $primary-light;
	border-radius: 50px;
	background: $white;
	font-size: 10px;
	color: $primary-main;

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	&:enabled:hover,
	&:enabled:focus {
		color: $primary-main;
		background-color: $white;
		box-shadow: none;
		border: 1px solid $primary-light;
	}
}
