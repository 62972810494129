@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.main {
	max-width: 350px;
	width: 100%;
	height: max-content;

	position: sticky;
	top: 0;
	background-color: transparent;
	z-index: 10;
	display: flex;
	justify-content: center;
	@include changeTransformFromRight;

	&.close {
		@include changeTransformToRight;
	}

	&.orgStyle {
		max-width: 328px;
	}

	.content {
		max-width: 350px;
		width: 100%;
		background: #fff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.separator {
			width: 100%;
			border: none;
			height: 1px;
			background-color: $primary-bg;
		}

		&__filters {
			width: 100%;
			padding: 10px 25px;
		}
	}
}
