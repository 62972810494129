@import '~assets/styles/variables';

.dashboard_item {
	width: 300px;
	height: 300px;
	background-color: #fff;
	border: 1px solid #f1f3ff;
	box-sizing: border-box;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 45px 27px 29px 27px;
}
.item__ico {
	width: 37px;
	height: 37px;
	background: #f1f3ff;
	border-radius: 50%;
	margin-bottom: 28px;
}
.item__caption {
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: #5b768b;
	margin-bottom: 15px;
}
.item__count {
	font-weight: 500;
	font-size: 80px;
	line-height: 100%;
	text-align: center;
	letter-spacing: -5px;
	color: #214ba5;
	margin-bottom: 16px;
}
.item__navButton {
	width: 30px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #c6ccfe;
	border-radius: 50%;
}
.item__navButton,
.item__ico {
	position: relative;
	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 18px;
		height: 18px;
		object-fit: contain;
	}
}
