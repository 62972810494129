@import '~assets/styles/variables';

.container {
	// max-width: 1096px;
	// min-width: 885px;
	width: 100%;
	padding: 27px 34px;
	margin-right: 2.4vw;
	background-color: #fff;
	height: min-content;
	// overflow-x: scroll;

	.table {
		margin-top: 22px;

		.tableItem__options {
			max-width: 97px;
			width: 100%;
			height: 33px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
		}

		.loader {
			width: min-content;
			margin: 0 auto;
		}
	}
}
