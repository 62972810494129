@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 600px;
}

.placeholdersTable {
  display: table;
  table-layout: fixed;
  width: 100%;
	border-collapse: collapse;

  &__photo {
    width: 100%;
    justify-content: center;
  }

	&__image {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		object-fit: cover;
	}

	th.placeholdersTable__name,
	th.placeholdersTable__description {
		max-width: 600px;
	}

  td.placeholdersTable__name,
  td.placeholdersTable__description {
    span {
      @include multilineThreeDots(2);
    }
  }

	&__edit {
		&-icon {
			cursor: pointer;

			&:hover {
				path {
					fill: $primary-main;
				}
			}
		}
	}

	th {
		text-align: start;
		padding: 10px 16px;
		font-size: 12px;
    font-weight: 700;
		color: $primary-main;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.208px;
    font-style: normal;
    background-color: $primary-bg;

		&:first-child {
			border-radius: 8px 0 0;
      width: 120px;
		}

		&:last-child {
			border-radius: 0 8px 0 0;
		}
	}

	th:first-child {
		width: 120px;
	}

	th:last-child {
		width: 60px;
	}

	td {
    padding: 10px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.208px;

		&:first-child {
			padding: 10px;
      width: 120px;
    }
	}
}
