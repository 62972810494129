@import '~assets/styles/variables';

.storeCouponForm {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_label {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
    }

    &_required {
      color: $required;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 60px;

    &_button {
      display: flex;
      justify-content: center;
      width: 200px !important;
      max-width: 200px !important;

      &_loader {
        width: 46px;
        margin: unset !important;
      }
    }
  }
}
