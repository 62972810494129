@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input_text {
	position: relative;
	input {
		@include inputMain;


		&:not(:focus) {
			& + .input_text_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		@include inputEdit;
	}
}
