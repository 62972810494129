@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	width: 100%;

	.form {
		max-width: 530px;
		margin-top: 2.5vh;

		.groupName {
			max-width: 510px;
		}

		.filterType {
			width: 100%;

			&__inputContainer {
				width: 100%;

				.inputContainer {
					&__row {
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: relative;

						.filterType__select {
							max-width: 510px;
							margin-right: 15px;
						}
					}
				}
			}
		}

		&__age,
		&__location {
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 512px;
			width: 100%;
			position: relative;
			@include changeOpacityToVisibleAnimation;

			& > div {
				max-width: 230px;

				& > div {
					max-width: 230px;
				}
			}

			& > span {
				position: relative;
				bottom: -8px;
				font-size: 14px;
				line-height: 18px;
				color: $text-light;
			}
		}

		&__gender {
			max-width: 512px;
			& > div {
				margin-bottom: 0px;

				& > div {
					max-width: none;
				}
			}
		}

		&__recent {
			max-width: 255px;

			& > div > div {
				max-width: none;
			}
		}

		.actions {
			margin-top: 5.3vh;
			button:last-child {
				margin-top: 15px;
			}
		}
	}
}

.label__unvisible {
	opacity: 0;
	pointer-events: none;
}

.loader {
	max-width: 260px;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
