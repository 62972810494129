@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: $lineClamp;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-width: 600px;
}

.quotesTable {
	border-collapse: collapse;

	&__image {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		object-fit: cover;
	}

	th.quotesTableDate,
	th.quotesTableName {
		max-width: 600px;
	}

	td.quotesTableDate,
	td.quotesTableName {
		span {
			@include multilineThreeDots(2);
		}
	}

	&__edit {
		&-icon {
			cursor: pointer;

			&:hover {
				path {
					fill: $primary-main;
				}
			}
		}
	}

	th {
		text-align: start;
		padding: 10px 16px;
		font-size: 12px;
		font-weight: 700;
		color: $primary-main;
		line-height: 20px; /* 166.667% */
		letter-spacing: -0.208px;
		font-style: normal;
		background-color: $primary-bg;

		&:first-child {
			border-radius: 8px 0 0;
		}

		&:last-child {
			border-radius: 0 8px 0 0;
		}
	}

	th:first-child {
		width: 230px;
	}

	th:last-child {
		width: 60px;
	}

	td {
		height: 88px;
		padding: 16px;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px; /* 128.571% */
		letter-spacing: -0.208px;
	}
}

.menuItem {
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #E4E7FF;
	}
}
.actionsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	.actionsText {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		color: #1c2f4c;
	}
}
