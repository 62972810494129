.wrapper {
	display: flex;
  flex-direction: column;
  align-items: center;
  padding: unset;
  overflow-y: unset;
}
.logo {
	height: auto;
	// width: 100%;
	max-width: 722px;
	max-height: 80vh;
}

.popup {
  height: auto;
  padding: 42px 95px !important;
  top: 0;
}

.preview {
  cursor: pointer;
}
