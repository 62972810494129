@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input__textarea {
	position: relative;
	min-height: 100px;

	textarea {
		@include inputTextArea;

		&:not(:focus) {
			& + .input__textarea_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		@include inputEdit;
	}
}
