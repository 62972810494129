.actions {
    // width: 95px;
    // width: 100%;

    &__content {
        width: 100%;
        display: flex;

        & > * {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}