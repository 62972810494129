.wrap {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.input {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 12px;

    padding: 48px 20px;
    max-width: 297px;

    //border: 1px dashed #214ba5;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%234B5EF5FF' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 20px;

		.uploadIcon {
			width: 36px;
			height: 36px;
		}

		.uploadText {
			display: flex;
			align-items: center;
			gap: 4px;

			input[type='file'] {
				display: none;
			}

			.uploadLabel {
				font-size: 18px;
        padding: 10px 0;
        color: #214ba5;
				text-decoration: underline #214ba5;
				cursor: pointer;
        font-weight: 400;
        line-height: 131%; /* 23.58px */
        letter-spacing: -0.18px;
			}

			span {
				color: #1c2f4c;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}

	.errorsBlock {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.errorMessage {
			font-size: 12px;
			color: #d00074;
		}
	}
}
