@keyframes opacityToVisible {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes opacityToUnvisible {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes transformFromRight {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes transformToRight {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(100%);
	}
}
