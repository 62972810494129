.main {
	width: 10px;
	height: 13px;
	outline: none;
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 16px;

	img {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}
