.messaging-create-channel__user-results{
    position: absolute;
    top: 79%;
    left: 35px;
    width: 295px;
}


.messaging__channel-list__header{
    position: sticky;
    top: 0;
}