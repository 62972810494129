@import '~assets/styles/variables';

.placeholders {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 28px 34px;
	background-color: $white;

	&__search {
	}
}
