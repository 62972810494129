@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	@include onboardingForm;

	.title {
		display: block;
		margin: 0 auto;
		margin-top: 3.3vh;
		margin-bottom: 2.1vh;
	}

	.form {
		@include onboardingForm;

		&__link,
		&__link:visited {
			max-width: 160px;
			width: 100%;
			display: block;
			color: $text-light;
			align-self: start;
		}

		.button__container {
			max-width: 300px;
			width: 100%;
			margin-top: 2.5vh;
			display: flex;
			align-items: center;
		}
	}

	.create_account {
		margin-top: 2vh;
	}

	.login_options {
		margin-top: 2.5vh;
		max-width: 94px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
