@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.dropdown_select {
	height: 40px;
	width: 100%;
	outline: none;
	border: 1px solid $primary-light;
	border-radius: 30px;
	transition: border 0.5s, border-radius 0.3s;
	font-size: 18px;
	line-height: 20px;

	&.md {
		max-width: 255px;
	}

	&.maxWidthNone {
		max-width: none;
	}

	&.roles,
	&.coordinator {
		z-index: 3;
	}

	&.disabled {
		background-color: #eff1f3;
	}

	.disabled_span {
		color: #b1b7bb;
	}

	&__options {
		z-index: 99;
		display: none;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		border: 1px solid $primary-bg;
	}

	&__options > div,
	&__title {
		min-height: 40px;
		width: 100%;
		outline: none;
		user-select: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 5px 0px;
		padding-left: 30px;
		padding-right: 24px;
	}

	&__title {
		position: relative;
		border-radius: 30px;
		color: $text-light;
		transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
		position: relative;

		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 80%;
			overflow: hidden;
		}

		&_selected {
			color: inherit;
		}

		&__vector {
			position: absolute;
			right: 17px;
			display: flex;
			justify-content: space-between;
			width: 7px;
			height: 9px;
			overflow: visible;
			transform: rotate(-90deg);
		}
	}

	&__active {
		position: relative;
		border: 1px solid transparent;

		.dropdown_select {
			height: inherit;
			// z-index: 5;
			overflow: hidden;

			&__title {
				height: 40px;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				background-color: $secondary-main;
				color: #fff;
				transition: background-color 0.3s, color 0.3s, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
				position: relative;

				&__vector {
					transform: rotate(0deg);
				}
			}

			&__options {
				width: 100%;
				position: relative;
				display: block;
				@include changeOpacityToVisibleAnimation;
				max-height: 115px;
				overflow-y: auto;
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */

				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar {
					display: none;
				}

				div {
					background-color: #fff;
					border-bottom: 1px solid $primary-bg;

					&.roles {
						text-transform: capitalize;
					}
				}

				div:hover {
					background-color: $primary-bg;
				}

				div:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

.LangSwitch__image {
	width: 74px;
	cursor: pointer;
}
.wrap_langSwitch {
	display: flex;
	align-items: center;
	position: relative;
	.dropdown_select {
		border: none;
		&__options {
			position: absolute;
			top: 100%;
		}
		&__title,
		&__options__item {
			padding-left: 15px;
			padding-right: 12px;
		}
	}
	.dropdown_select__active {
		.dropdown_select__title_selected span {
			color: #fff !important;
		}
	}
}
