@import '~assets/styles/mixins';

.main {
	width: 100%;
	min-height: 101px;
	overflow: hidden;
	display: flex;
	align-items: center;

	&:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	&:last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	& > span {
		width: 100%;
		padding: 5px 0px;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.208px;
		text-overflow: ellipsis;
		display: block;
		overflow: hidden;
	}
}

.chesedName {
	// max-width: 155px;
	width: 100%;
	font-size: 14px;
	line-height: 18px;
	font-weight: bold;
	display: flex;
	gap: 5px;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img {
		width: 16px;
		height: 14px;
		margin-left: 2px;
		display: block;
		object-fit: contain;
		align-self: center;
		// flex: 0 0 22px;
	}
}

.manualOpportunity__mark {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 3px;

	&_admin {
		background-color: #ffbf00;
		color: #fff;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.3px;
		margin: 0;
		padding: 4px;
		border-radius: 8px;
		font-weight: 400;
	}

	&_manual {
		display: flex;
		align-items: center;
	}
}

.previous_completed_mark {
	path {
		fill: #214ba5;
	}
}

.age {
	// max-width: 60px;
}

.gender {
	// max-width: 80px;
	text-transform: capitalize;
}

.location {
	// max-width: 150px;
}

.totalTimeSpent {
	// max-width: 65px;
	// text-align: center;
}

.forms {
	// max-width: 150px;
}

.email {
	// max-width: 190px;
}

.phone {
	// max-width: 130px;
}

.date {
	// max-width: 80px;
}

.time {
	// max-width: 80px;
}

.coordinator {
	// max-width: 150px;
}

.fullName {
	// max-width: 155px;
	width: 100%;
}

.options {
	// max-width: 95px;
	// min-width: 70px;

	button:last-child {
		margin-right: 0;
		margin-left: 5px;
	}
}

.type {
	// max-width: 90px;
	// width: 100%;
}

.total_volunteers {
	// max-width: 70px;
	text-align: center;
}

.total_users {
	// max-width: 40px;
	text-align: center;
}

.total_oppotunities {
	// max-width: 780x;
	text-align: center;
}

.total_oppotunity_hours {
	// max-width: 90px;
	text-align: center;
}
