.button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: transparent;
	height: 25px;
	width: 25px;

	&:disabled {
		opacity: 0.4;
		cursor: default;
	}
}
