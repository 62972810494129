// @import '~assets/styles/mixins';
@import '~assets/styles/variables';

.input_text {
	position: relative;
	input {
		padding: 8px 38px 8px 24px;
		width: 100%;
		outline: none;
		border: 1px solid $primary-light;
		border-radius: 30px;
		font-size: 18px;
		line-height: normal;
		transition: all 300ms;

		&::placeholder {
			color: $text-light;
			padding-top: 12px;
		}

		&:focus {
			border-color: #ffa133;
			transition: border-color 300ms;
		}

		&:disabled {
			background-color: $disabled;
			color: #b1b7bb;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			box-shadow: 0 0 0 50px white inset !important;
			font: 400 18px 'Rubik';
			color: $text-main;
		}

		&:not(:focus) {
			& + .input_text_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		position: absolute;
		right: 20px;
		top: 12px;
		width: 16px;
		height: 16px;
		opacity: 0;
		transition: 0.2s;
		background-image: url('../../../../../../assets/image/inputs/edit.svg');
		cursor: pointer;
	}
}
