.avatar {
	display: flex;
	align-items: center;
	max-width: 160px;

	img {
		width: 56px;
		height: 56px;
		border-radius: 50%;
    object-fit: cover;
	}
}
