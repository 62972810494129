@import '~assets/styles/variables';

.container {
	width: 100%;

	&__field {
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			margin-top: 0;
		}
	}

	.fieldInfo {
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.3px;
		color: $text-light;
	}
}
