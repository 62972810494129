@import '~assets/styles/variables';

.dialog {
  width: 643px;

  &__header {
    display: flex;
    justify-content: end;
    padding: 30px;
    gap: 14px;

    &_button {
      padding: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    &_button {
      max-width: 240px !important;
    }

    &_cancel_button {
      background-color: #E4E7FF !important;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 52px 52px 52px;

    &_title {
      font-size: 24px;
      color: $text-main;
      font-weight: 500;
      margin-bottom: 6px;
      line-height: 26.4px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__image {
        cursor: pointer;
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 20px;

        &_hover {
          position: relative;
          width: 150px;
          height: 150px;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            width: inherit;
            height: inherit;
            top: 0;
            left: 0;
            border-radius: 20px;
            transition: all 0.3s;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 36px;
            height: 36px;
          }

          &:hover {
            transition: all 0.3s;

            &:before {
              background: rgba(238, 240, 255, 0.60);
              backdrop-filter: blur(3px);
              transition: all 0.3s;
            }

            &:after {
              background: url('../../../../assets/image/icons/trash.svg');
            }
          }
        }
      }

      &__textfield {
        input {
          padding: 8px 50px 8px 16px;
        }
      }
    }

    &_label {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
    }

    &_limit {
      font-size: 12px;
      color: $text-light;
      line-height: 20px;

      &_error {
        color: $required;
      }
    }

    &_required {
      color: $required;
    }
  }
}

.reset {
  & .MuiPaper-rounded {
    border-radius: 10px !important;
  }
}

