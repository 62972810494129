@import '~assets/styles/variables';

.wrap {
	position: relative;
}
.googleAct {
	font-size: 18px;
	width: 100%;
	height: 40px;
	padding: 11px 5px 11px 42px;
	border-radius: 30px;
	border: 1px solid $primary-light;
	text-overflow: ellipsis;
	box-shadow: none;
	z-index: 2;
	transition: all 0.2s ease;

	&::placeholder {
		color: $text-light;
	}

	&:active,
	&:focus {
		outline: none;
		border-color: $secondary-main;
	}

	position: relative;
}

.search_icon {
	position: absolute;
	left: 15px;
	top: 12px;
	z-index: 3;
}
.pac-container {
	border-radius: 30px;
	border: 1px solid $primary-light;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-top: 0;
	box-shadow: none;
	z-index: 50 !important;

	&:after {
		display: none;
	}

	.pac-item {
		padding: 5px 10px;
		padding-left: 17px;
		cursor: pointer;

		&:hover {
			background-color: $primary-bg;
		}

		&:last-child {
			padding-bottom: 9px;
		}
	}

	.pac-icon.pac-icon-marker {
		width: 11px;
		height: 14px;
		content: url('~assets/image/location.svg');
		object-fit: contain;
		background: none;
	}

	.pac-matched {
		font-weight: inherit;
	}
}

.pac-target-input:not(:-webkit-autofill) {
	animation: none;
	outline: none;
}
