@import '~assets/styles/variables';

.wrap {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 0 52px 52px;

	.templateDownload {
		background: none;
		border: none;
		width: fit-content;

		font-size: 18px;
		color: #214ba5;
		text-decoration: underline #214ba5;
	}

	.quotesBlock {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.tableHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;

		padding: 4px;
		width: 100%;

		span {
			font-size: 14px;
			font-weight: 400;
			color: #5b768b;
		}

		.clearAllButton {
			padding: 8px;
			border-radius: 10px;
			background-color: #214ba5;

			color: #ffffff;
			font-size: 18px;
		}
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		gap: 32px;
		margin-top: 28px;

		&_button {
			max-width: 240px !important;
		}
	}
}
