@import '~assets/styles/mixins';

.storeItems {
  @include orgPage;

  display: flex;
  flex-direction: column;
  gap: 40px;

  &_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #1C2F4C;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    padding: 28px 32px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
