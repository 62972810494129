@import '~assets/styles/variables';

.main {
	width: 54px;
	height: 54px;
	border-radius: 50%;
	margin-left: 3vw;
	background-color: $primary-bg;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.line {
		width: 25px;
		height: 3px;
		background-color: $primary-main;
		margin-bottom: 5px;
		border-radius: 5px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
