@import '~assets/styles/variables';

.addCoupons {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__switch {
    display: flex;
    align-items: center;
    gap: 24px;

    &_button {
      width: 200px !important;
      height: 44px !important;

      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 131% !important;
      letter-spacing: -0.18px !important;
      margin: unset !important;

      color: $text-light !important;
      border: unset !important;

      &_active {
        color: $primary-main !important;
        border: 1px solid var(--Primary-Blue-1, #214BA5) !important;
        font-weight: 500 !important;
      }
    }
  }

}
