@import '~assets/styles/variables';

.storeImageField {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;

  &__head {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $text-main;

      &_required {
        color: $required;
      }
    }

    &_subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.3px;
      color: $text-light;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    grid-column-gap: 21.6px;
    grid-row-gap: 21.6px;
    grid-template-rows: 134px;
    margin-top: 8px;

    &_item {
      position: relative;
      padding-top: 14px;

      &:first-child::before {
        content: 'Primary';
        position: absolute;
        bottom: 12px;
        left: 0;
        padding: 4px;
        border-radius: 2px;
        background: $primary-light02;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.3px;
        color: $primary-main;
        cursor: grab;
      }

      &:hover .storeImageField__images_item_remove {
        opacity: 1;
      }

      &_remove {
        opacity: 0;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: all 0.3s;
        z-index: 9999;
      }


      &_image {
        width: 120px;
        height: 120px;
        border-radius: 20px;
        cursor: grab;
        object-fit: cover;
      }
    }

    &_upload {
      margin-top: 14px;
      width: 120px;
      height: 120px;
      cursor: pointer;
    }
  }
}
