@import '~assets/styles/mixins';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
	top: 0;
	left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(241, 243, 255, 0.8);
  z-index: 3;

	.appeal {
		max-width: 495px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 6.3vh;

		span:first-child {
			margin-bottom: 3vh;
		}

		.subtitle {
			display: block;
			font-size: 18px;
			line-height: 23px;
			letter-spacing: -0.01em;
			text-align: center;
		}
	}

	.options {
		max-width: 260px;
		width: 100%;
		margin: 0 auto;
    margin-top: 3vh;


    button {
			margin-top: 1.5vh;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}
}
