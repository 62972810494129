@import '~assets/styles/mixins';

.container {
	@include orgPage;
}

.block_title_action_button {
	display: flex;
	max-width: 310px;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
}
.item_action,
.item_count {
	cursor: pointer;
}

.title_wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	height: 44px;

	h1 {
		margin-bottom: 0;
		margin-right: 20px;
	}
}

.upload, .share {
	width: 134px;
	height: 44px;
	font-size: 18px;
	color: $white;
	letter-spacing: -0.3px;
	border-radius: 10px;

	&:active {
		background-color: $primary-dark;
	}

	&,
	&:hover {
		border: 1px solid $primary-main;
		background-color: $primary-main;
		box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
	}

	div {
		display: flex;
		align-items: center;
	}

	span {
		margin-left: 4px;
	}
}

.share {
  position: relative;
  color: $primary-main;

  &_container {
    position: relative;
    margin-left: 1cqw;
  }

  &_ttl {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: $text-main;
    margin-bottom: 24px;
  }

  &_viaItem {
    width: 180px;
    height: 45px;
    border: 1px solid #c6ccfe;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 14px;
    display: flex;
    padding: 0 25px;
    align-items: center;
    background-color: #fff;
    color: inherit;
    text-decoration: none;

    &:hover {
      background-color: $primary-bg;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      pointer-events: none;
      background-color: $disabled;
    }
  }

  &:active {
    background-color: #F1F3FF;
  }

  &, &:hover {
    border: 1px solid $primary-bg;
    background-color: $primary-bg;
  }
}

.icon {
	path {
		fill: white;
	}
}

.share_icon {
  path {
    fill: $primary-main;
  }
}

.viaItem__ico {
  width: 19px;
  height: 20px;
  object-fit: contain;
  margin-right: 16px;
}

.share_dd {
  background: #ffffff;
  border: 1px solid #f1f3ff;
  box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 8px;
  width: 239px;
  padding: 24px 32px;
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 2;
  @include changeOpacityToVisibleAnimation;
}
