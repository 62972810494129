@import '~assets/styles/variables';

.button_close {
	transform: translate(5px, -15px) rotate(90deg);
	outline: none;
	background-color: transparent;
	border-radius: 50%;
	transition: all 0.2s ease;
	padding: 9px;
	width: 17px;
	height: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	&:hover {
		background-color: #fff;
	}

	&__icon {
		width: 12px;
		height: 12px;
		object-fit: contain;
	}
}

.popup {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($primary-bg, 0.8);
	z-index: 999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__title {
		display: block;
		margin-bottom: 2vh;
		font-size: 60px;
	}

	button {
		margin-bottom: 2vh;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
