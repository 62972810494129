@import '~assets/styles/variables';

.container {
	width: 100%;

	.header {
		display: flex;

		.label {
			display: block;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 10px;
			margin-right: 25px;

			&__action {
				font-size: 14px;
				line-height: 21px;
				color: $text-light;
				cursor: pointer;
			}
		}
	}
	.who {
		// max-width: 480px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 10px;

		label {
			max-width: 145px;
			width: 100%;

			&.small {
				max-width: 133px;
			}

			.input {
				display: none;
			}
		}
	}
}
