.tableActions {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__button {
    width: 103px !important;
    height: 44px !important;
  }
}
