@import '~assets/styles/mixins';

.fullName {
	max-width: 155px;
	width: 100%;

	&.unsetMaxWidth {
		max-width: unset;
	}

	&__content {
		min-height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		overflow: hidden;
		text-overflow: ellipsis;

		.text {
			@include twoLineText;
		}

		.styled_text {
			font-size: 18px;
			line-height: 20px;
			font-weight: bold;
			text-align: left;
		}
	}
}
