.challenges {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 28px 32px;
  background-color: #ffffff;

  .challenges__header {
    display: flex;
    justify-content: space-between;
  }
}
