@import '~assets/styles/variables';

.main {
	max-width: 230px;
	width: 100%;
	display: flex;
	flex-direction: column;

	.slider {
		width: 100%;
		// width: 280px;
		padding: 0;

		&__rail {
			height: 1px;
			top: 5px;
			background: $primary-main;
		}

		&__track {
			height: 3px;
			top: 3px;
		}

		&__thumb {
			width: 20px;
			height: 20px;
			border-radius: 6px;
		}
	}
}
.slider__value {
	margin-top: 24px;
	text-align: center;
	color: $text-light;
}
