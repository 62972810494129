@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.timePicker {
	&__container {
		position: relative;
	}

	&__inputContainer {
		position: relative;
	}

	&__input {
		width: 100%;
		border: 1px solid $primary-light;
		height: 40px;
		display: block;
		outline: none;
		font-size: 18px;
		box-sizing: border-box;
		transition: all 0.2s ease;
		line-height: 20px;
		user-select: none;
		padding-left: 30px;
		border-radius: 30px;
		padding-right: 15px;
		justify-content: center;
		background-color: #fff;

    &_empty {
      color: #5B768B !important;
    }

    &_remove_icon {
      position: absolute;
      right: 40px;
      top: 14px;
      width: 12px;
      height: 12px;
      cursor: pointer;

      path {
        fill: rgba(0, 0, 0, 0.54);
      }
    }

		&_icon {
			position: absolute;
			top: 0;
			padding: 0 5px;
			top: 50%;
			right: 5px;
			display: flex;
			position: absolute;
			transform: translateY(-50%);
			align-items: center;
			white-space: nowrap;
			margin-left: 8px;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			cursor: pointer;
			transition: all 0.2s ease;

			&:hover {
				background-color: $primary-bg;
			}
		}
	}
}

.form {
	min-height: 150px;
	max-height: 350px;
	max-width: 350px;
	// width: 100%;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 50%;
	padding: 5px 10px;
	border: 1px solid $primary-light;
	border-radius: 20px;
	z-index: 99;
	box-sizing: border-box;
	display: flex;
	gap: 10px;
	transform: translateX(-50%);
	@include changeOpacityToVisibleAnimation;

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}
}
