.main {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border: none;
	outline: none;
	padding: 0;
	background-color: transparent;

	.icon {
		display: block;	
		object-fit: contain;
	}
}
