.wrapper {
	max-width: 400px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;

	button {
		margin-top: 0;

		&:first-child {
			margin-right: 20px;
		}
	}
}
