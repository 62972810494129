.snackbar {
  display: inline-flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  width: 196px;
  height: 51px;

  border-radius: 8px;

  &__success, &__error {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &_title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_message {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__success {
    border: 1px solid #5CC469;
    background: #DBF4E9;

    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }

  &__error {
    border: 1px solid #C32B2B;
    background: #FEE;


    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }
}
