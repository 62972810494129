@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input__search {
	position: relative;
	z-index: 3;
  width: 260px;
  height: 40px;

	input {
		@include inputMain;
    height: 100%;
		padding-left: 52px;
    padding-right: 16px;
    font-size: 14px;
    line-height: 18.2px;

		&:not(:focus) {
			& + .input__search_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		@include inputEdit;
	}

	&_icon {
		position: absolute;
    margin: 5px;
		left: 16px;
		top: 7px;
		width: 14px;
		height: 14px;
		opacity: 1;
		transition: 0.2s;
		background-image: url('~assets/image/inputs/search.svg');
		cursor: pointer;
	}

	&.big {
		max-width: 260px;
		width: 100%;
		height: 40px;
	}
}
