@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	line-height: 18px;
	background-color: #fff;
	width: 100%;
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	display: grid;
	// grid-template-columns: 2fr 3fr 4fr 3fr 1fr 1fr 3fr 3fr 1fr 1fr 2fr;
	grid-gap: 10px;
	padding: 8px 15px;
	border-radius: 8px;
	@include changeOpacityToVisibleAnimation;

	&:nth-child(2n) {
		background-color: $container-bg;
	}
}

.volunteers {
	@extend .container;
	// grid-template-columns: 2fr 3fr 4fr 3fr 1fr 1fr 3fr 3fr 1fr 1fr 2fr 3.5fr;
	grid-template-columns: 3fr 4fr 4fr 4fr 1fr 1fr 3fr 3fr 1fr 1fr 2fr 3fr;
}

.oppotunities {
	@extend .container;
	grid-template-columns: 2fr 3fr 2fr 2fr 2fr 3fr 2fr 2fr 1fr;
}

.organizations {
	@extend .container;
	grid-template-columns: 2fr 3fr 3fr 3fr 2fr 4fr 4fr 2fr 2fr 2fr 2.5fr 1fr 2fr;
}

.invites {
	@extend .container;
	grid-template-columns: 1fr 2fr 2fr 3fr 2fr 1fr 1fr 2fr;
}

.oppotunitiesVolunteer {
	@extend .container;
	grid-template-columns: 3fr 2fr 2fr 2fr 4fr 3fr;
}

.viewMore {
	@extend .container;
	grid-template-columns: 3fr 3fr 2fr 2fr 4fr 3fr;
}

.suspend {
	border: 1px solid #f1f3ff;
	background-color: #f79d54 !important;
}

.users {
	@extend .container;
	// grid-template-columns: 2fr 2fr 2fr 4fr 2fr 2fr;
	grid-template-columns: 1fr 2fr 2fr 2fr 3fr;
}

.roles {
	@extend .container;
	// justify-items: center;
	align-items: center;
	grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
