@import '~assets/styles/mixins';

.wrapper {
	max-width: 400px;
	width: 100%;

	.title {
		margin-top: 2.5vh;
		margin-bottom: 1.5vh;
		text-align: center;
	}

	.subtitle {
		@include signUp-subtitle;
		text-align: center;
		display: block;
	}

	button {
		margin: 0 auto;
		margin-top: 2vh;
	}
}
