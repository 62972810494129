.actions {
  // max-width: 95px;
  // width: 100%;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;

    & > * {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.icon {
  width: 16px;
	height: 16px;
	padding: 0;
  display: block;
  object-fit: contain;
  cursor: pointer;
}
