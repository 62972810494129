.main {
	min-width: 60px;
	min-height: 101px;
	overflow: hidden;
	display: flex;
	align-items: center;
}
.actions {
	max-width: 95px;
	min-width: 70px;
  justify-self: center;
	overflow: visible;

	&__content {
		width: 100%;
		display: flex;

		& > * {
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}