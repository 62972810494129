.quill.without-toolbar .ql-container.ql-snow {
  border-radius: 30px;
}

.quill.without-toolbar .ql-editor {
  min-height: 40px;
  padding: 9px 50px 8px 16px;
  font-size: 18px;
  line-height: normal;
}

.quill.oppo .ql-container{
  min-height: 100px;
}

.quill.oppo .ql-editor {
  padding: 11px 40px 15px 24px;
  font-size: 18px;
  color: #000000;
}

.quill.oppo .ql-editor li {
  margin-top: unset;
}

.quill.without-toolbar .ql-editor.ql-blank::before {
  left: 16px;
  line-height: normal;
  font-family: Rubik, sans-serif;
}

.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 12px;
  border-radius: 30px 30px 0 0;
  border-color: #C6CCFE;
  border-bottom: 0;
  background-color: #f8f9ff;
}

.ql-snow .ql-formats {
  position: relative;
  display: flex;
  align-items: center;
  height: 29px;
  margin-right: unset !important;
  padding-right: 6px;
  padding-left: 6px;
}

.ql-snow .ql-formats:first-child {
  padding-left: unset;
}

.ql-snow .ql-formats:last-child {
  padding-right: unset;
}

.ql-snow .ql-formats:last-child:after {
  display: none;
}

.ql-snow .ql-formats:after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  background-color: #1C2F4C;
  right: 0;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button.ql-active svg path,
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-picker-options .ql-picker-item:hover {
  color: rgb(33, 75, 165);
  fill: rgb(33, 75, 165);
}

.ql-snow .ql-picker-options .ql-picker-item:hover {
  background-color: #f8f9ff;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #C6CCFE;
  border-radius: 4px;
  box-shadow: unset;
  padding: unset;
}

.ql-snow .ql-color-picker .ql-picker-options {
  flex-direction: row !important;
  flex-wrap: wrap;
}

.ql-snow .ql-color-picker .ql-picker-item, .ql-snow .ql-picker-options .ql-picker-item {
  border-radius: 4px;
}

.ql-snow .ql-color-picker .ql-picker-item:first-child {
  border: 1px solid #C6CCFE !important;
  background-color: #ffffff;
}

.ql-snow .ql-color-picker .ql-picker-item:first-child:hover {
  border: 1px solid #1C2F4C !important;
}

.ql-snow .ql-picker-options .ql-picker-item:hover {
  border: 1px solid #C6CCFE !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: unset;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-label {
  border: unset;
  padding: unset;
  padding-left: unset;
  padding-right: unset;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-color-picker {
  padding: unset;
  width: 20px;
  height: 20px;
  margin: 0 6px;
}

.ql-snow.ql-toolbar button svg {
  height: unset;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: '10px';
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: '12px';
  font-size: 12px !important;
}


.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: '14px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: '16px';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: '20px';
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: '24px';
  font-size: 24px !important;
}

.ql-container.ql-snow {
  border-color: #C6CCFE;
  border-radius: 0 0 30px 30px;
}

.ql-editor {
  min-height: 175px;
}

.ql-lineHeight-12px {
  line-height: 12px;
}
.ql-lineHeight-16px {
  line-height: 16px;
}
.ql-lineHeight-20px {
  line-height: 20px;
}
.ql-lineHeight-24px {
  line-height: 24px;
}
.ql-lineHeight-28px {
  line-height: 28px;
}

.ql-editor.ql-blank::before {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%; /* 23.58px */
  letter-spacing: -0.18px;
  color: #5B768B;
}

.ql-snow .ql-picker.ql-size {
  width: 130px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:not(.ql-active)::before {
  content: 'Select font size' !important;
  font-size: 14px !important;
}
