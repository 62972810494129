@import '~assets/styles/mixins';

.container {
	width: 100%;
	background-color: #fff;
	min-height: 66.25vh;
	padding: 7.35vh;
	position: relative;
	overflow-y: auto;
	@include changeOpacityToVisibleAnimation;
}
