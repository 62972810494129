.main {
	max-width: 912px;
	width: 100%;
	padding: 42px 95px;
	background-color: #fff;
	margin: 0 auto;
	border-radius: 10px;
	overflow-y: auto;
}

.title {
	text-transform: capitalize;
}
