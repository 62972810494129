$primary-main: #214ba5;
$primary-dark: #0017d0;
$primary-light: #c6ccfe;
$primary-light02: #e4e7ff;
$primary-bg: #f1f3ff;

$primary-light-orange-main-bg: #F79D54;
$primary-light-orange-bg: #FFF0DE;
$primary-light-orange-text: #E47C00;

$secondary-main: #ffa133;
$secondary-dark: #ef8100;
$secondary-light: #ffdba5;
$secondary-bg: #fff4e4;

$text-main: #1c2f4c;
$text-light: #5b768b;
$text-black: #000000;

$white: #ffffff;
$disabled: #eff1f3;

$gradients-blue: linear-gradient(180deg, #f1f3ff 0%, rgba(241, 243, 255, 0) 100%);
$gradients-orange: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);

$container-bg: #f8f9ff;

$required: #D00074;
