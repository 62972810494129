.button {
	&_confirm {
		margin-top: 4vh !important;
		margin-bottom: 2vh !important;
	}
}

.title {
	margin-top: 2.5vh !important;
  margin-bottom: 8px !important;
}

.subtitle {
  margin-top: 16px !important;
  font-weight: 300;
}
