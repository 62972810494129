@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $text-light;
}

.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 1px;
	left: 0;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	border: 2px solid $primary-light;
	background-color: $white;
	box-sizing: border-box;
	transition: all 0.2s ease;
}

.container:hover input ~ .checkmark {
	background-color: $white;
}

.container input:checked ~ .checkmark {
	background-color: $secondary-main;
	border: 2px solid $secondary-main;
}

.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.container input:checked ~ .checkmark:after {
	display: block;
}

.container .checkmark:after {
	content: url('~assets/image/checkbox-arrow.svg');
	left: 1px;
	top: -4px;
	width: 8px;
	height: 6px;
}
