@import '~assets/styles/variables';



.menuButton {
  background-color: transparent;

  &_active {
    svg {
      path {
        fill: $primary-main;
      }
    }
  }
}

.menuDropdown {
  display: flex;
  flex-direction: column;
  width: 196px;
  background-color: white;

  box-shadow: 0 0 20px 0 rgba(33, 75, 165, 0.15);
  border-radius: 8px;
  border: 1px solid $primary-light;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;

  color: $text-main;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: $primary-light02 !important;
  }
}
