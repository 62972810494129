@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.buttons_block {
    margin-top: 44px;
    display: flex;
    justify-content: end;

    button {
        margin-right: 19px;

        &:last-child {
            margin-right: 10px;
        }
    }
}

.whiteContainer {
    padding: unset;
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
}

