.actions {
	// max-width: 95px;
	// min-width: 70px;
	overflow: visible;

	&__content {
		width: 100%;		
		display: flex;
		justify-content: center;

		& > * {
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
