.button {
	width: 30px;
	height: 30px;
	cursor: pointer;
	padding: 0;
	border: 0;
	outline: none;
	background-color: transparent;
	transition: all 0.2s ease;

	&__icon {
		width: 100%;
		height: 100%;
		object-fit: contain;

		&_disabled {
			opacity: 0.4;
		}
	}
}
