@import '~assets/styles/mixins';

.wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	max-width: 450px;
	width: 100%;
	button:last-child{
		margin-left: 10px;
	}
	.button_invite,
	.button_create {
		cursor: pointer;
		max-width: 30px;
		max-height: 30px;
		width: 100%;
		height: 100%;
		outline: none;
		background-color: transparent;

		&__icon {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.button_create {
		@include changeOpacityToVisibleAnimation;
	}

	.item {
		cursor: pointer;
		max-width: 30px;
		max-height: 30px;
		width: 100%;
		height: 100%;
	}
}
