@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.dropdown_select {
	// max-width: 160px;
	height: 40px;
	width: 100%;
	outline: none;
	border: 1px solid $primary-light;
	border-radius: 30px;
	transition: border 0.5s, border-radius 0.3s;
	font-size: 18px;
	line-height: 20px;
	z-index: 2;

	&.md {
		max-width: 255px;
	}

	&.maxWidthNone {
		max-width: none;
	}

	&__options {
		z-index: 99;
		display: none;
		z-index: 0;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		border: 1px solid $primary-bg;

		&_center {
			.dropdown_select__options__item {
				display: flex;
				justify-content: center;
				padding: 0 15px;
			}
		}
	}

	&__options > div,
	&__title {
		min-height: 40px;
		width: 100%;
		outline: none;
		user-select: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 5px 0px;
		padding-left: 30px;
		padding-right: 24px;
	}

	&__title {
		position: relative;
		border-radius: 30px;
		color: $text-light;
		transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
		position: relative;

		&_selected {
			color: inherit;
		}

		&__vector {
			position: absolute;
			right: 17px;
			display: flex;
			justify-content: space-between;
			width: 7px;
			height: 9px;
			overflow: visible;
			transform: rotate(-90deg);
		}
	}

	&__active {
		position: relative;
		border: 1px solid transparent;

		.dropdown_select {
			height: inherit;
			z-index: 5;
			overflow: hidden;

			&__title {
				height: 40px;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				background-color: $secondary-main;
				color: #fff;
				transition: background-color 0.3s, color 0.3s, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
				position: relative;

				&__vector {
					transform: rotate(0deg);
				}
			}

			&__options {
				z-index: 5;
				width: 100%;
				position: relative;
				display: block;
				@include changeOpacityToVisibleAnimation;
				max-height: 115px;
				overflow-y: auto;
				//disable scrollbar
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */

				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar {
					display: none;
				}

				&_center {
					.dropdown_select__options__item {
						border: none;
						position: relative;

						&::after {
							position: absolute;
							bottom: 0;
							content: '';
							width: calc(100% - 30px);
							height: 1px;
							background-color: $text-main;
						}

						&:last-child::after {
							display: none;
						}
					}
				}

				div {
					background-color: #fff;
					border-bottom: 1px solid $primary-bg;
				}

				div:hover {
					background-color: $primary-bg;
				}

				div:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

.dropdown_select_multiple {
	max-width: 160px;
	min-height: 40px;
	width: 100%;
	outline: none;
	border: 1px solid $primary-light;
	border-radius: 30px;
	transition: border 0.5s, border-radius 0.3s;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.208px;
	z-index: 5 !important;

	&_w245 {
		max-width: 245px;
	}
	&_fs18 {
		font-size: 18px;
	}

	&__options {
		display: none;
		z-index: 0;
	}

	&__options > div,
	&__title {
		min-height: 44px;
		height: inherit;
		width: 100%;
		outline: none;
		user-select: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding-left: 33px;
		padding-right: 24px;
	}

	&__title {
		position: relative;
		border-radius: 30px;
		color: $text-light;
		transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
		position: relative;

		&_selected {
			color: inherit;
		}

		&__vector {
			position: absolute;
			right: 17px;
			width: 7px;
			height: 9px;
			transform: rotate(-90deg);
		}
	}

	&__active {
		position: relative;
		border: 1px solid transparent;

		.dropdown_select_multiple {
			z-index: 5;

			&__title {
				height: 40px;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				background-color: $secondary-main;
				color: #fff;
				transition: background-color 0.3s, color 0.3s, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
				position: relative;

				&__vector {
					width: 9px;
					height: 7px;
					transform: rotate(0deg);
				}
			}

			&__options {
				height: 115px;
				width: 100%;
				z-index: 5;
				position: relative;
				display: flex;
				justify-content: space-between;
				@include changeOpacityToVisibleAnimation;
				overflow: hidden;
				border: 1px solid $primary-bg;
				border-top: none;

				border-bottom-left-radius: 20px;
				border-bottom-right-radius: 20px;
				background-color: #fff;

				&__column {
					padding: 0;
					display: flex;
					flex-direction: column;
					overflow-y: auto;
					overflow-x: hidden;
					padding: 0 5px;
					margin-right: 5px;

					&:last-child {
						margin-right: 0;
					}

					//disable scrollbar
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */

					/* Hide scrollbar for Chrome, Safari and Opera */
					&::-webkit-scrollbar {
						display: none;
					}

					&_disabled {
						overflow-y: hidden;
						background-color: $disabled;
						opacity: 0.4;
					}

					.options__item {
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 10px 0;
						width: inherit;
						border-bottom: 1px solid $text-main;

						&:hover {
							background-color: $primary-bg;
						}

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}
