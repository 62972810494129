@import '~assets/styles/variables';

.main {
	width: 100%;
	height: 50px;
	background-color: transparent;
	border: 1px solid $primary-light;
	border-radius: 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	padding: 5px;
	transition: all 0.2s ease;

	&:before {
		display: none;
		position: absolute;
		content: '';
		cursor: pointer;
		width: 21px;
		height: 21px;
		border-radius: 50%;
		background-color: $secondary-main;
		top: -10px;
		right: 10px;
	}

	&:after {
		display: none;
		position: absolute;
		content: '';
		cursor: pointer;
		width: 4px;
		height: 7px;
		transform: rotate(45deg);
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		top: -6px;
		right: 17px;
	}

	.icon {
		width: 35px;
		height: 35px;
		margin-left: 5px;
		margin-right: 10px;
		position: relative;

		&__label {
			width: 100%;
			height: 100%;
			position: absolute;
			border-radius: 5px;
			background: $primary-light02;
			transform: perspective(90px) rotate(30deg) rotateX(40deg);
		}

		&__image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			position: absolute;
			z-index: 55;
		}
	}

	.placeholder {
		font-size: 14px;
		line-height: 20px;
		font-weight: normal;
	}

	&.checked {
		border-color: $secondary-main;
		box-shadow: inset 0 0 0 1px $secondary-main;

		&::after,
		&::before {
			display: block;
		}

		.icon__label {
			background-color: $secondary-light;
		}
	}
}
