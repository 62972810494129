@import '~assets/styles/mixins';

.main {
	width: 100%;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.208px;
	// white-space: nowrap;
	text-align: left;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;

	// &:first-child {
	// 	border-top-left-radius: 8px;
	// }

	// &:last-child {
	// 	border-top-right-radius: 8px;
	// }
}

.image {
	margin-left: 10px;
	display: inline-block;
	transition: transform 0.2s;

	&.desc_dir {
		transform: rotate(180deg);
	}
}

.avatar {
	// max-width: 160px;
	text-align: center;
}

.age {
	text-align: right;
}

.gender {
	// max-width: 80px;
	text-align: center;
	text-transform: capitalize;
}

.location {
	// max-width: 150px;
}

.totalTimeSpent {
	// max-width: 65px;
	// text-align: center;
}

.fullName {
	max-width: 155px;
}

.chesedName {
	// max-width: 155px;
	// font-size: 14px;
	line-height: 18px;
	font-weight: bold;
}

.age {
	// max-width: 60px;
	text-align: center;
}

.gender {
	// max-width: 80px;
	text-align: center;
	text-transform: capitalize;
}

.totalTimeSpent {
	// max-width: 65px;
	// text-align: center;
	align-self: center;
}

.grade {
  text-align: start;
}

.forms {
	// max-width: 150px;
}

.email {
	// max-width: 190px;
}

.phone {
	// max-width: 130px;
}

.date {
	// max-width: 80px;
}

.time {
	// max-width: 80px;
}

.coordinator {
	// max-width: 150px;
}

.volunteers {
	// max-width: 165px;
}

.options {
	// max-width: 95px;
	// min-width: 70px;
}

.volunteers_actions {
	// max-width: 95px;
	// min-width: 50px;
}

.checkbox {
	// width: 25px;
}

.type {
	// max-width: 95px;
	// width: 100%;
	// white-space: break-spaces;
}

.contacts {
	// max-width: 120px;
	// margin-right: 5px;
}

.owner {
	// max-width: 120px;
}

.total_volunteers {
	// max-width: 70px;
}

.total_users {
	// max-width: 40px;
}

.total_oppotunities {
	// max-width: 80px;
}

.total_oppotunity_hours {
	// max-width: 90px;
	// white-space: break-spaces;
}
