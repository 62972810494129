@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.row {
    display: flex;
    justify-content: center;
    align-items: center;

    // &:hover,
    // &:focus {

    // }



    .button {
        width: 138px;
        height: 39px;
        border-radius: 10px;
        line-height: 18px;
        color: #5B768B;
        background-color: #F1F3FF;

        &:not(:last-child) {
            margin-right: 16px;
        }

        &:hover,
        &:focus {
            color: #214ba5;
            background-color: #C6CCFE;

             svg path{
                 fill: #214ba5;
             }
        }

        .icon {
            width: 12px;
            height: 12px;
            margin-right: 8px;
            fill: #C6CCFE;

            &:hover,
            &:focus {
                fill: #214ba5;
            }
        }
    }
}
