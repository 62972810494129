@import '~assets/styles/variables';

.ChallengeForm {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 52px 52px 52px;
    width: 100%;

    &_title {
      font-size: 24px;
      color: $text-main;
      font-weight: 500;
      margin-bottom: 6px;
    }

    &_pickers {
      display: flex;

      &_date {
        max-width: 240px;
      }

      &_time {
        margin-left: 24px;
        max-width: 240px;
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__image {
        cursor: pointer;
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 20px;

        &_hover {
          position: relative;
          width: 150px;
          height: 150px;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            width: inherit;
            height: inherit;
            top: 0;
            left: 0;
            border-radius: 20px;
            transition: all 0.3s;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 36px;
            height: 36px;
          }

          &:hover {
            transition: all 0.3s;

            &:before {
              background: rgba(238, 240, 255, 0.60);
              backdrop-filter: blur(3px);
              transition: all 0.3s;
            }

            &:after {
              background: url('../../../../../../assets/image/icons/trash.svg');
            }
          }
        }
      }

      &__ideas {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &_addMore {
          width: 140px !important;
          height: 46px !important;
          padding: 10px 16px !important;
          border-radius: 10px !important;
          gap: 8px;

          span {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23.58px;
            letter-spacing: -0.18px;
          }

          svg path {
            fill: #214ba5;
          }
        }
      }
    }

    &_label {
      font-size: 16px;
      font-weight: bold;
    }

    &_limit {
      font-size: 12px;
      color: $text-light;

      &_error {
        color: $required;
      }
    }

    &_required {
      color: $required;
    }

    &_idea {
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    &_actions {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__right {
        display: flex;
        gap: 24px;
      }
    }

    &_button {
      width: 200px !important;
      max-width: 200px !important;
    }
  }
}
