.channel-preview__container {
	height: 77px;
	/* padding-bottom: 8px; */
	padding-left: 18px;
	padding-right: 18px;
	/* border-radius: 8px; */
	border-top: 1px solid #F1F3FF;
	border-bottom: 1px solid #F1F3FF;
	background-color: #ffffff;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* padding-left: 8px; */
}
.channel-preview__container + .channel-preview__container {
border-top: none;
}
.channel-preview__container:hover {
	background: #E4E7FF;
	/* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
	transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container:hover {
	background: #2c2c30;
}

.channel-preview__container.selected {
	background: #E4E7FF;
	/* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
	transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container.selected {
	background: #2c2c30;
}

.channel-preview__content-wrapper {
	display: flex;
	justify-content: space-between;
	margin-left: 8px;
	margin-right: 8px;
	width: 100%;
}

.channel-preview__content-top {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0;
	/* height: 18px; */
	margin-bottom: 4px;
}

.channel-preview__content-top {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}	

.channel-preview__content-name {
	font-family: Helvetica Neue, sans-serif;
	font-weight: 500;
	font-size: 15px;
	color: #000000;
	margin: 0;
	max-width: 158px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.str-chat.dark .channel-preview__content-name {
	color: #ffffff;
}

.channel-preview__content-time {
	font-family: Helvetica Neue, sans-serif;
	font-size: 11px;
	color: #858688;
	margin: 0;
}

.channel-preview__content-message {
	font-family: Helvetica Neue, sans-serif;
	font-size: 13px;
	line-height: 16px;
	margin: 0;
	color: #858688;
	height: 16px;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

p.channel-preview__unread-message-count,
.channel-preview__unread-message-count{
	width: 14px;
	height: 14px;
	border-radius: 50%;
	font-size: 12px;
	color: #ffffff;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFA133;
	margin-top: 10px;
}

.channel-preview__container .str-chat__avatar {
	margin-right: 0;
}

.channel-preview__avatars {
	display: flex;
	align-items: center;
	height: 40px;
	min-width: 40px;
	max-width: 40px;
	border-radius: 20px;
	overflow: hidden;
}

.channel-preview__avatars.two div:first-child {
	position: relative;
	right: 10px;
}

.channel-preview__avatars.two div:nth-child(2) {
	position: relative;
	right: 30px;
}

.channel-preview__avatars.two span {
	width: 20px;
	overflow: hidden;
}

.channel-preview__avatars.three span {
	width: 20px;
	overflow: hidden;
}
