.main {
	margin: 0 auto;
	width: min-content;

	&.page_center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.center_disabled {
		margin-left: 0;
	}
}

.disableMargin {
  margin: unset;
}
