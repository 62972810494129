@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.couponsListForm {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__input {
    display: flex;
    flex-direction: column;
    gap: 32px;

    & .templateDownload {
      background: none;
      border: none;
      width: fit-content;

      font-size: 18px;
      color: #214ba5;
      text-decoration: underline #214ba5;
    }
  }

  &__table {
    border-collapse: collapse;
    position: relative;
    width: 100%;

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 4px;
      width: 100%;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #5b768b;
      }
    }

    &_clearAllButton {
        padding: 8px;
        border-radius: 10px;
        background-color: #214ba5;

        color: #ffffff;
        font-size: 18px;
    }

    &__thead {
      height: 40px;

      &_ID {
        border-radius: 8px 0 0;
      }

      &_Actions {
        border-radius: 0 8px 0 0;
      }
    }

    &__tbody {
      tr {
        height: 52px;
      }

      &_Actions {
        padding: 6px 16px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          height: 60px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    &__thead, &__tbody {
      th {
        background-color: #F1F3FF;
        padding: 10px 16px;
        color: #214ba5;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.208px;
        cursor: default;
      }

      td {
        padding: 4px 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.208px;
        color: $text-black;

        span {
          @include multilineThreeDots(1);
          line-break: anywhere;
        }
      }

      &_ID {
        min-width: 128px;
        max-width: 128px;
      }

      &_Coupons {
        width: 612px;
      }

      &_Actions {
        min-width: 88px;
        max-width: 88px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 60px;

    &_button {
      display: flex;
      justify-content: center;
      width: 200px !important;
      max-width: 200px !important;

      &_loader {
        width: 46px;
        margin: unset !important;
      }
    }
  }
}
