@import '~assets/styles/mixins';

.container {
	@include orgPage;
}

.block_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 46px;
	width: 100%;
}
.block_title_action_button {
	display: flex;
	max-width: 310px;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
}
.item_action,
.item_count {
	cursor: pointer;
}
