@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	width: 100%;
	padding: 27px 34px;
	background-color: #fff;
	height: min-content;
	@include changeOpacityToVisibleAnimation;

	.table {
		margin-top: 22px;

		.loader {
			width: min-content;
			margin: 0 auto;
		}

		.action_cell {
			align-self: center;
			justify-self: center;
		}
	}
	
}
