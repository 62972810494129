.wrapper {
	width: 100%;
	height: 100px;
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	padding-right: 54px;
	box-shadow: 0px 2px 4px rgba(33, 75, 165, 0.1);
	z-index: 11;
	position: sticky;
	top: 0;
	background-color: #fff;

	.content {
		display: flex;
		max-width: 265px;
		width: 100%;
		justify-content: flex-end;
		// todo if enable lang switch - uncomment
		// justify-content: space-between;
		position: relative;

		&__image {
			width: 54px;
			height: 54px;
			position: relative;
			margin-left: 25px;

			.avatar {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}
}
.logoBlockWrapper {
	display: flex;
}
