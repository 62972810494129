@import '~assets/styles/mixins';

.contact {
	width: 100%;
	font-size: 12px;
	color: #1c2f4c;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;

	span {
		margin-bottom: 10px;
		font-size: 14px;
	}

	a {
		text-decoration: none;
		color: #1c2f4c;
	}

	.text {
		@include lineThreeDots;
	}

	.multiline_text {
		@include twoLineText;
	}
}
