@import '~assets/styles/mixins';

.main {
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;

	input {
		margin-right: 5px;
	}

	label:hover {
		text-decoration: underline;
	}
}

.organizationName {
	@include lineThreeDots();
}
