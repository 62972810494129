@import './variables';

body {
	font-family: 'Rubik', sans-serif;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: $text-main;
}

button {
	user-select: none;
}

.disabledClass {
  .MuiInputBase-input{
    background-color: #eff1f3;
  }
}