@import '~assets/styles/variables';

.form {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 0 52px 52px 52px;

	&_item {
		display: flex;
		flex-direction: column;
		gap: 4px;

		&__image {
			cursor: pointer;
			width: 150px;
			height: 150px;
			object-fit: cover;
			border-radius: 20px;

			&_hover {
				position: relative;
				width: 150px;
				height: 150px;
				cursor: pointer;

				&:before {
					content: '';
					position: absolute;
					width: inherit;
					height: inherit;
					top: 0;
					left: 0;
					border-radius: 20px;
					transition: all 0.3s;
				}

				&:after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 36px;
					height: 36px;
				}

				&:hover {
					transition: all 0.3s;

					&:before {
						background: rgba(238, 240, 255, 0.6);
						backdrop-filter: blur(3px);
						transition: all 0.3s;
					}

					&:after {
						background: url('../../../../../../assets/image/icons/trash.svg');
					}
				}
			}
		}
	}

	&_footer {
		display: flex;
		justify-content: flex-end;
		gap: 32px;
		margin-top: 28px;

		&_button {
			max-width: 240px !important;
		}
	}

	.form_pickers_date {
		max-width: 165px;
		width: 100%;

    &__item {
      width: 240px;
      input {
        padding: 8px 16px;
      }
    }
	}

	&_label {
		font-size: 16px;
		font-weight: 500;
    line-height: normal;
    font-style: normal;
  }

	&_limit {
		font-size: 12px;
		color: $text-light;

		&_error {
			color: $required;
		}
	}

	&_required {
		color: $required;
	}
}
