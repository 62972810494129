.title {
	padding-left: 19px;

	&__hide {
		display: none;
	}
}

.img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	border-radius: 32px;
	background-color: #f1f3ff;
}
