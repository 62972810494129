.chatWrapper {
	display: flex;
	height: 70vh;
	// height: 100%;
	overflow: hidden;

	// &.org {
	// 	max-height: 70vh;
	// }
	// align-items: flex-start;
}

// .str-chat-channel{
//   max-height: 70vh;
// }
