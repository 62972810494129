@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	@include orgPage;
}

.block_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// margin-bottom: 46px;
	max-width: 1310px;
}
.block_title_action_button {
	display: flex;
	width: 310px;
	align-items: center;
	justify-content: space-between;
}
.item_action,
.item_count {
	cursor: pointer;
}
.container {
	display: flex;
}
.button_back {
	flex: 1 1 100%;
	max-width: 100px;
	display: flex;
	align-items: baseline;
	line-height: 20px;
}
.button_backArr {
	transform: rotate(180deg);
	position: relative;
	margin-right: 18px;
}
.form {
	background: #ffffff;
	box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
	border-radius: 10px;
	margin-bottom: 20px;
}
.form__item {
	display: flex;
	flex-direction: column;
}
.form__title {
	margin-top: 60px;
	font-size: 24px;
	line-height: 26px;
	letter-spacing: -0.208px;
	color: #1c2f4c;
}
