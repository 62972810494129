.main {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border: none;
	outline: none;
	padding: 0;
	background-color: transparent;

	.icon {
		width: 16px;
		height: 16px;
		display: block;
		object-fit: contain;
	}
}