@import '~assets/styles/mixins';

.container {
	display: flex;
	flex-direction: column;
	height: min-content;
	@include orgPage;

	.content {
		width: 100%;
		display: flex;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			flex-direction: column;
			flex: 0 1 100%;
			min-width: 200px;
		}
	}

	.generateGroup {
		min-width: 285px;
	}
}

.tabNavItem {
	max-width: 190px;
}
