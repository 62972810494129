@import '~assets/styles/mixins';

.main {
	@include inputContainer;
}

.height_small {
	height: 60px;
}

.margin_disabled {
	margin-bottom: 0;
}

.signUp {
	@include inputContainer;
}

.uploadLogo {
	width: 100%;
	height: 138px;
}

.textArea {
	height: 140px;
}

.disableHeight {
	height: auto;
}
