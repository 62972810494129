.messageListWrap {
	flex: 1 1 auto;
}
.str-chat-channel {
	// max-height: 70vh;
	height: 100%;
}
.str-chat.messaging,
.str-chat.commerce {
	background-color: #fff;
}

.str-chat__li--single,
.str-chat__li--single .str-chat__message--me {
	.str-chat__message-text-inner {
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(33, 75, 165, 0.02);
		border-radius: 8px;
	}
}

.str-chat__li--single .str-chat__message--me .str-chat__message-text-inner,
.str-chat__li--single .str-chat__message-simple--me .str-chat__message-text-inner {
	background: #ffdba5;
}

.str-chat__message .str-chat__reaction-list,
.str-chat__message-commerce .str-chat__reaction-list,
.str-chat__message .str-chat__reaction-list::after,
.str-chat__message .str-chat__reaction-list::before,
.str-chat__message-commerce .str-chat__reaction-list::after,
.str-chat__message-commerce .str-chat__reaction-list::before {
	background: none;
}

.messaging__channel-list {
	p {
		font-family: 'Rubik';
		font-size: 16px;
		margin: 0;
		padding: 0;
	}
}
.str-chat__li--middle,
.str-chat__li--bottom,
.str-chat__li--top {
	.str-chat__message-text-inner,
	.str-chat__message--me .str-chat__message-text-inner{
		border-radius: 8px;
	}
}
