@import '~assets/styles/mixins';

.container {
	display: flex;
	flex-direction: column;
	height: min-content;

	.content {
		display: flex;
		flex-direction: column;
		flex: 0 1 100%;
		min-width: 200px;
		transition: all 0.2s ease;

		&__wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	.generateGroup {
		min-width: 285px;
	}

	.disabled {
		pointer-events: none;
	}
}

.tabNavItem {
	max-width: 190px;
}
