.pageContainer {
	display: flex;
	flex-direction: column;
	gap: 36px;
	padding: 28px 32px;
	background-color: #ffffff;

	.controlsContainer {
		display: flex;
		justify-content: space-between;
	}

	.noItemsContainer {
		width: 100%;
		max-width: 700px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: auto;
		gap: 60px;
		background: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);

		.noItemsImage {
			width: 320px;
			height: 320px;
		}

		.noItemsTextContainer {
			display: flex;
			flex-direction: column;
			gap: 32px;
			align-items: center;

			.noItemsTitle {
				font-size: 24px;
				font-weight: 500;
				line-height: 26.4px;
				text-align: center;

				color: #1c2f4c;
			}

			.noItemsText {
				font-size: 14px;
				font-weight: 400;
				line-height: 18.2px;
				text-align: center;

				color: #000000;
			}
		}
	}
}
