@import '~assets/styles/mixins';

.container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	.appeal {
		max-width: 495px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 6.3vh;

		span:first-child {
			margin-bottom: 3vh;
		}

		.subtitle {
			display: block;
			font-size: 18px;
			line-height: 23px;
			letter-spacing: -0.01em;
			text-align: center;
		}
	}

	.options {
		max-width: 260px;
		width: 100%;
		margin: 0 auto;

		button {
			margin-top: 1.5vh;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}
}
