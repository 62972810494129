@import '~assets/styles/mixins';
@import '~assets/styles/variables';

h2 {
	align-self: flex-start;
}

.form__container {
	max-width: 530px;
	width: 100%;
	margin-top: 2.5vh;

	.form {
		.label {
			@include popupLabel;

			&__unvisible {
				@include popupLabel;
				opacity: 0;
				pointer-events: none;
			}
		}

		&__join_type {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 1.55vh;
			margin-bottom: 3.45vh;
		}

		&__pickers {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 2.3vh;

			&_date {
				max-width: 165px;
				width: 100%;
			}

			&_time {
				max-width: 345px;
				margin-left: 10px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				z-index: 99;

				.timer {
					max-width: 165px;
				}
			}
		}

		.coordinator {
			div:first-child {
				z-index: 20;
			}
		}

		&__location {
			height: 45px;
			margin-bottom: 1.8vh;

			div {
				height: auto;
			}

			input {
				@include changeOpacityToVisibleAnimation;
			}

			.button_zoom,
			.button_zoom:hover {
				@include default-button;
				@include font-button;
				@include changeOpacityToVisibleAnimation;
				max-width: 280px;
				width: 100%;
				border: none;
				background-color: $primary-bg;
				color: $primary-main;

				&:active {
					background-color: $primary-light;
					color: $primary-dark;
				}

				&:focus {
					box-shadow: none;
					-webkit-box-shadow: none;
				}

				svg {
					margin-right: 4px;
				}
			}
		}

		&__opportunitie_address {
			display: flex;
			margin-bottom: 2.3vh;

			button:first-child {
				margin-right: 12px;
			}
		}

		&__forWho {
			width: 100%;
			margin-bottom: 15px;

			.who__wrapper {
				max-width: 300px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		.additional {
			&__title {
				cursor: pointer;
				margin-bottom: 2.3vh;

				&_arrow {
					margin-left: 10px;
					width: 10px;
					height: 10px;
					transform: rotateZ(90deg);
					transition: all 0.2s ease;

					&_active {
						transform: rotateZ(-90deg);
					}
				}
			}

			&__content {
				max-width: 533px;
				width: 100%;
				@include changeOpacityToVisibleAnimation;

				&_close {
					@include changeOpacityToUnvisibleAnimation;
				}

				.details__content {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					.ageLimit {
						width: 100%;
						display: flex;
						justify-content: space-between;
						z-index: 60;

						& > div {
							max-width: 255px;
						}
					}

					.moreDetails {
						width: 100%;
						display: flex;
						justify-content: space-between;

						& > div {
							max-width: 255px;
						}
					}
				}
			}
		}
	}
}

.loader {
	width: 100%;
}

.dialog__form_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.8vh;
}
