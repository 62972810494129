@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: $lineClamp;
	-webkit-box-orient: vertical;
}

@mixin lineThreeDots() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.container {
	flex-grow: 1;
	max-width: 1920px;
	container-type: inline-size;

  span {
    line-height: 1.2;
  }
}

@container (min-width: 0px) {
	.board {
		padding: 1.6cqw 7.2cqw;
		display: grid;
		grid-template-columns: repeat(3, 27cqw);
		grid-template-rows: auto repeat(2, 19.7cqw) 8cqw auto;
		grid-template-areas:
			'hd hd hd'
			'th to mb'
			'wh ss mb'
			'ft ft mb'
			'bt bt tt';
		grid-gap: 2cqw;
	}

	.top {
		grid-area: hd;
		width: 100%;
		padding-inline: 2cqw;
		display: flex;
		align-items: center;
		gap: 2cqw;

		&__organization {
			display: flex;
			align-items: center;
			gap: 1.6cqw;

			&__avatar {
				height: 5.2cqw;
				border-radius: 50%;
				object-fit: cover;
			}

			&__name {
				font-size: 2.5cqw;
				font-weight: 500;
				letter-spacing: -0.05cqw;
				word-wrap: break-word;
				@include multilineThreeDots(3);
			}
		}

		&__title {
			flex-grow: 1;
			background: transparent;
			border: none;
			resize: none;
			outline: none;
			font-size: 1.4cqw;
			color: $primary-main;
			transition: all 0.3s;
      width: 100%;
      max-height: 100%;

      &_wrapper {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: 8cqw;
        padding: 8px;
      }

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&_edit {
        border: 1px solid #c6ccfe;

        .top__title {
          color: $text-main;
        }
			}
		}
	}

	.card {
		border-radius: 2cqw;
		padding: 2.5cqw 1.6cqw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 1cqw;
		background: #fff;

		&_first {
			grid-area: th;

			.card__count {
				color: $secondary-main;
			}
		}

		&_second {
			grid-area: to;

			.card__count {
				color: $primary-main;
			}
		}

		&_third {
			display: flex;
			align-items: center;
			justify-content: center;
			grid-column-start: wh;
			grid-column-end: ss;
		}

		&_forth {
			grid-area: ss;
		}

		&__title {
			justify-self: start;
			font-size: 2.5cqw;
			font-weight: 500;
		}

		&__count {
			margin-block: auto;
			max-width: 100%;
			font-size: 6cqw;
			font-weight: 600;
			@include lineThreeDots();
		}

		&__volunteer {
			max-width: 100%;
			padding-inline: 1.6cqw;
			display: flex;
			gap: 1.6cqw;

			//&__avatar {
			//	flex-shrink: 0;
			//	width: 4.1cqw;
			//	height: 4.1cqw;
			//	max-width: unset;
			//	max-height: unset;
			//	border-radius: 50%;
			//	object-fit: cover;
			//}

      &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 4.1vw;
        height: 4.1vw;
        max-width: unset;
        max-height: unset;
        border-radius: 50%;
        object-fit: cover;
        text-transform: capitalize;
        background: linear-gradient(113.8deg, #6e41fe -2.28%, #214ba5 100.88%);
        color: #fff;
        font-size: 2vh;
      }

			&__info {
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 0.4cqw;
			}

			&__name {
				font-size: 1.6cqw;
				font-weight: 500;
				word-wrap: break-word;
				@include multilineThreeDots(2);
			}

			&__grade {
				font-size: 1.2cqw;
			}

			&__oppo {
				width: auto !important;

				&_padding {
					padding-inline: 7.2cqw 1.6cqw !important;
				}
			}
		}

		&__subTitle {
      width: max-content;
      padding-inline: 1.6vw;
      word-wrap: break-word;
      font-size: 1.6vw;
      @include multilineThreeDots(2);
      -webkit-line-clamp: 3;

      &_bold {
        text-transform: capitalize;
        font-weight: 500;
      }
		}

    &__opponame {
      max-width: 96%;
      @include multilineThreeDots(1);
      text-overflow: ellipsis;
      margin-left: 4px;
    }
	}

	.bottom {
		grid-area: ft;
		height: 100%;
		padding-inline: 0.8cqw;
    padding: 8px;
    border-radius: 20px;
		display: flex;
		align-items: center;
		gap: 16px;
		background: #fff;

    &_edit {
      border: 1px solid #c6ccfe;
    }

		&__avatar {
			flex-shrink: 0;
			height: 4cqw;
			object-fit: cover;
		}

		&__subTitle {
			width: 100%;
			max-height: 100%;
			background: transparent;
			resize: none;
			outline: none;
			border: none;
			font-size: 1.2cqw;
			color: $primary-main;
			transition: all 0.3s;
      align-content: center;

      &::-webkit-scrollbar-track {
				background: transparent;
			}

			&_edit {
				color: $text-main;
			}
		}
	}

	.media {
		grid-area: mb;

		&__file {
			width: 100%;
			height: 100%;
			border-radius: 1.6cqw;
			object-fit: cover;
			transition: height 0.3s;
		}
	}

	.upload {
		max-width: 100% !important;
		margin-bottom: 8px !important;
	}

	.error {
		color: #d00074;
		font-size: 12px;
	}
}

.input {
	display: none;
}

.button {
	grid-area: bt;
	width: 100%;
	padding-right: 16cqw;

	display: flex;
	justify-content: flex-start;

	button:last-child {
		margin-left: 16px;
	}
}

.disable_submit {
	background-color: $primary-main !important;
	color: #fff !important;
	opacity: 0.4;
}

.cancel_button {
	background-color: $primary-bg !important;
	color: $primary-main !important;
}


.recentlyOppo {
  max-width: 100%;
  display: flex;
}
