@import '~assets/styles/mixins';

.upload {
	&__wrapper {
		width: 100px;
		height: 100px;
		margin-right: 1.3vw;
		position: relative;
		@include changeOpacityToVisibleAnimation;
	}

	&_preview {
		width: 100%;
		height: 100%;
		object-fit: cover;
		outline: none;
		border-radius: 50%;
	}
}
