@import '~assets/styles/variables';

.dialog {
	width: 930px;

	&__header {
		display: flex;
		justify-content: end;
		padding: 30px;

		&_button {
			padding: 0;
		}
	}

	&__form_header {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 0 52px 32px 52px;

		&_title {
			font-size: 24px;
			color: $text-main;
			font-weight: 500;
			margin-bottom: 6px;
		}

		&_tabs {
			display: flex;
			align-items: center;
			gap: 24px;

			&_tab {
        padding: 10px 16px;
				border-radius: 40px;
				color: #5b768b;
				background-color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 131%;
        letter-spacing: -0.18px;
        width: 200px;
      }

			&_active {
				border: 1px solid #214ba5;
				color: #214ba5;
				font-weight: 500;
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		gap: 32px;
		margin-top: 28px;

		&_button {
			max-width: 240px !important;
		}
	}
}

.reset {
	& .MuiPaper-rounded {
		border-radius: 10px !important;
	}
}
