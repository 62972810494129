.badges {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	.badge {
		&_new {
			width: 25px;
			height: 12px;
			margin-right: 10px;
		}

		&_member {
			width: 15px;
			height: 15px;
			object-fit: contain;
		}
	}
}
