.logo__container {
	align-self: flex-start;
	max-height: 60px;

	a {
		display: block;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
      max-height: 60px;
			object-fit: contain;
		}
	}
}
