.main {
	width: 95px;
	// width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		width: 100%;
	}
}
