.container {
	width: 100%;
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	background-color: #fff;

	.filter {
		max-width: 300px;
		width: 100%;
		display: flex;
		display: flex;
		align-items: center;

		&__image {
			width: 38px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.2s ease;

			&.disabled {
				filter: grayscale(0.7);
				pointer-events: none;
			}
		}
	}
}
